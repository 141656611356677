<template>
    <div>
        <div class="top-container">
            <ul>
                <li><img alt="phone" src="@/assets/icons/phone.png">+86-19314091645</li>
                <li><img alt="email" src="@/assets/icons/email.png">global@senshcn.com</li>
            </ul>
        </div>
        <div :class="{ 'sticky-nav': isSticky }" class="nav-container">
            <div class="auto-container">
                <el-row class="auto-arrange">
                    <el-col :md="6" :sm="8" :xs="16">
                        <div class="logo">
                            <router-link to="/home">
                                <img alt="" src="@/assets/img/logo.png">
                            </router-link>
                        </div>
                    </el-col>

                    <!-- 移动端菜单按钮 -->
                    <el-col :sm="0" :xs="8" class="text-right">
                        <el-button class="menu-btn" type="text" @click="isCollapse = !isCollapse">
                            <i :class="isCollapse ? 'el-icon-close' : 'el-icon-menu'"></i>
                        </el-button>
                    </el-col>

                    <!-- PC端导航 -->
                    <el-col :sm="16" :xs="0">
                        <div class="nav-wrapper">
                            <ul class="navigation">
                                <li :class="{'active': currentRoute === 'home'}"
                                    @click="goToPage('')">{{ $t('nav.home') }}
                                </li>
                                <li :class="{'active': currentRoute === 'about'}"
                                    @click="goToPage('about')">{{ $t('nav.about') }}
                                </li>
                                <li :class="{'active': isProductActive}"
                                    class="has-submenu"
                                    @click.stop="goToPage('products')">
                                    {{ $t('nav.products') }}
                                    <ul class="submenu">
                                        <li v-for="item in productSubmenu"
                                            :key="item.id"
                                            :class="{'active': currentProductId === item.id}"
                                            @click.stop="goProductInfo(item.id)">
                                            {{ item.title }}
                                        </li>
                                    </ul>
                                </li>
                                <li :class="{'active': isSolutionActive}"
                                    class="has-submenu"
                                    @click.stop="goSolutionDetail()">
                                    {{ $t('nav.solution') }}
                                    <ul class="submenus">
                                        <li v-for="item in solutionSubmenu"
                                            :key="item.id"
                                            :class="{'active': currentSolutionId === item.id}"
                                            @click.stop="goSubItemDetail(item.id)">
                                            {{ item.title }}
                                        </li>
                                    </ul>
                                </li>
                                <li :class="{'active': currentRoute === 'case'}"
                                    @click="goToPage('case')">{{ $t('nav.case') }}
                                </li>
                                <li :class="{'active': currentRoute === 'news'}"
                                    @click="goToPage('news')">{{ $t('nav.news') }}
                                </li>
                                <li :class="{'active': currentRoute === 'contact'}"
                                    @click="goToPage('contact')">{{ $t('nav.contact') }}
                                </li>
                            </ul>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <!-- 移动端导航菜单 -->
            <div :class="{ 'show': isCollapse }" class="mobile-nav">
                <el-menu
                        :default-active="activeIndex"
                        class="mobile-menu"
                >
                    <el-menu-item index="1" @click="goToPage('')">{{ $t('nav.home') }}</el-menu-item>
                    <el-menu-item index="2" @click="goToPage('about')">{{ $t('nav.about') }}</el-menu-item>

                    <el-submenu index="3">
                        <template slot="title">
                            <span>{{ $t('nav.products') }}</span>
                        </template>
                        <el-menu-item
                                v-for="item in productSubmenu"
                                :key="item.id"
                                :index="'3-' + item.id"
                                @click="goProductInfo(item.id)"
                        >
                            {{ item.title }}
                        </el-menu-item>
                    </el-submenu>

                    <el-submenu index="4">
                        <template slot="title">
                            <span>{{ $t('nav.solution') }}</span>
                        </template>
                        <el-menu-item
                                v-for="item in solutionSubmenu"
                                :key="item.id"
                                :index="'4-' + item.id"
                                @click="goSubItemDetail(item.id)"
                        >
                            {{ item.title }}
                        </el-menu-item>
                    </el-submenu>

                    <el-menu-item index="5" @click="goToPage('case')">{{ $t('nav.case') }}</el-menu-item>
                    <el-menu-item index="6" @click="goToPage('news')">{{ $t('nav.news') }}</el-menu-item>
                    <el-menu-item index="7" @click="goToPage('contact')">{{ $t('nav.contact') }}</el-menu-item>
                </el-menu>
            </div>
        </div>
    </div>
</template>

<script>
import {getProductDropDownMenu, getSolutionDropDownMenu} from "@/api";

export default {
    name: 'HeaderIndex',
    inject: ['reload'],
    data() {
        return {
            isSticky: false,
            productSubmenu: [],
            solutionSubmenu: [],
            isCollapse: false,
            activeIndex: '1',
            currentRoute: 'home',
            currentProductId: null,
            currentSolutionId: null
        }
    },
    created() {
        this.getList();
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    computed: {
        isProductActive() {
            return this.currentRoute === 'products' ||
                this.currentRoute === 'productsDetail' ||
                this.currentProductId !== null;
        },
        isSolutionActive() {
            return this.currentRoute === 'solution' ||
                this.currentRoute === 'solutionDetail' ||
                this.currentSolutionId !== null;
        }
    },
    watch: {
        '$route': {
            handler(to) {
                this.updateActiveMenu(to);
            },
            immediate: true
        }
    },
    methods: {
        handleScroll() {
            const scrollPosition = window.scrollY
            this.isSticky = scrollPosition > 114
        },
        getList() {
            getProductDropDownMenu().then((res => {
                this.productSubmenu = res
            }))
            getSolutionDropDownMenu().then((res => {
                this.solutionSubmenu = res
            }))
        },
        updateActiveMenu(route) {
            this.currentRoute = route.name;

            // 重置子菜单高亮状态
            this.currentProductId = null;
            this.currentSolutionId = null;

            // 根据路由参数设置子菜单高亮
            if (route.params.id) {
                if (route.name === 'productsDetail') {
                    this.currentProductId = route.params.id;
                    this.updateActiveIndex(route.name, route.params.id);
                } else if (route.name === 'solutionDetail') {
                    this.currentSolutionId = route.params.id;
                    this.updateActiveIndex(route.name, route.params.id);
                }
            } else {
                this.updateActiveIndex(route.name);
            }
        },
        goToPage(routeName) {
            if (this.$route.name !== routeName) {
                let path = '';
                if (routeName === '') {
                    path = '/';
                } else {
                    path = `/${routeName}`.replace(/\/+$/, '');
                }
                this.$router.push(path).then(() => {
                    this.reload();
                    this.isCollapse = false;
                    this.currentRoute = routeName;
                    // 重置子菜单高亮
                    this.currentProductId = null;
                    this.currentSolutionId = null;
                });
            }
        },
        goProductInfo(id) {
            if (this.$route.params.id !== id) {
                this.$router.push({
                    path: `/singleCategoryList/detail/${id}`
                }).then(() => {
                    this.reload();
                    this.isCollapse = false;
                    this.currentRoute = 'productsDetail';
                    this.currentProductId = id;
                    this.currentSolutionId = null;
                    this.updateActiveIndex('productsDetail', id);
                });
            }
        },
        goSolutionDetail() {
            if (this.$route.name !== 'solution') {
                this.$router.push({
                    path: '/solution'
                }).then(() => {
                    this.reload();
                    this.isCollapse = false;
                    this.currentRoute = 'solution';
                    this.currentProductId = null;
                    this.currentSolutionId = null;
                });
            }
        },
        goSubItemDetail(id) {
            if (this.$route.params.id !== id) {
                this.$router.push({
                    name: 'categoryList',
                    params: {id}
                }).then(() => {
                    this.reload();
                    this.isCollapse = false;
                    this.currentRoute = 'categoryList';
                    this.currentProductId = null;
                    this.currentSolutionId = id;
                    this.updateActiveIndex('categoryList', id);
                });
            }
        },
        // 修改更新activeIndex的方法
        updateActiveIndex(routeName, id) {
            const routeMap = {
                'home': '1',
                'about': '2',
                'products': '3',
                'productsDetail': '3',
                'solution': '4',
                'solutionDetail': '4',
                'case': '5',
                'news': '6',
                'contact': '7'
            };

            // 处理子菜单高亮
            if (routeName === 'productsDetail' && id) {
                this.activeIndex = `3-${id}`;
            } else if (routeName === 'solutionDetail' && id) {
                this.activeIndex = `4-${id}`;
            } else {
                this.activeIndex = routeMap[routeName] || '1';
            }
        }
    }
}
</script>
