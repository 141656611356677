export default {
    nav: {
        title: '植物工厂一一合肥创农生物科技有限公司',
        home: '首页',
        about: '企业介绍',
        products: '产品中心',
        solution: '解决方案',
        case: '项目案例',
        news: '企业新闻',
        contact: '联系我们',
        latestNews: '最新资讯',
        describe: '合肥创农生物科技有限公司（以下称创农生物）是一家集研发、生产、销售及技术服务于一体的高新技术企业，致力于向全球推广系统化、智能化、标准化的农业种植解决方案，各类种植设备定制及技术服务',
        address: '合肥市肥西县经济开发区派河大道与苏岗路交口合肥创新科技园B3栋1-5层'
    },
    home: {
        featured: {
            title: '我们提供智慧农业种植的专业技术标准化解决方案',
            title1: '智慧植物工厂解决方案',
            title2: '种植设备定制解决方案',
            title3: '集装箱种植系统解决方案',
            desc: '从土壤栽培到无土栽培到自动化植物工厂，叶菜侠科技持续专注于叶菜生产技术研究',
            desc1: '创农通过高精度智能计算机和传感系统，对植物生长全过程的温度、湿度、光照、 CO2浓度以及营养液等环境条件进行自动控制，使植物生长过程不受或很少受自然条件影响，实现农作物周年连续生产，节约农用耕地和水资源，植物无污染、无农药、无重金属残留，代表着未来农业的发展方向。',
            desc2: '创农生物为全球广大的家庭种植爱好者、企业、科研院所、高校等提供专业室内水培种植设备定制服务，基于对客户多方面的需求，提供给客户可行经济高效的专业解决方案，帮助客户解决生活生产科研遇到的问题。',
            desc3: '集装箱种植系统继承了LED植物生长灯、温湿度控制、二氧化碳控制、营养液供给系统及自助控制等技术，为植物生长提供了理想环境，保证植物连续生长而不受虫害及污染的影响。'
        },
        solution: {
            title: '我们提供基于三大场景的叶菜标准化生产解决方案',
            desc: '从土壤栽培到无土栽培到自动化植物工厂，叶菜侠科技持续专注于叶菜生产技术研究'
        },
        products: {
            title: '创农生物致力于成为全球领先的无土栽培全产业链服务商',
            desc: '让更多的人加入到智慧设施农业行业建设中来，推动全球农业产业4.0的快速发展'
        },
        system: {
            title: '全新一代“阵列”系统',
            title2: '自动化DFT水培解决方案',
            desc: '“LEAFYMAN®️阵列®️”自动化DFT水培系统是专为现代化都市配套，在城市附近实现周年生产差异化叶菜产品的自动化解决方案。系统由温室、DFT水培系统、自动化生产线构成。为应对日益紧张的农业劳动力和都市绿色供应链，自动化水培系统可以实现少人/无人化365天不间断叶菜供应。'
        },
        Solutions: {
            title: '解决方案',
            desc: '致力于向全球推广系统化、智能化、标准化的农业种植解决方案'
        },
        case: {
            title: '项目案例',
            desc: '用户需求用什么技术解决，比技术可以应用在什么领域更重要'
        },
        introduce: {
            title: '让室内种植变得简单高效',
            title1: '是创农生物科技的宗旨',
            title2: '如何全方位保证室内种植的简单高效',
            itemTitle1: '种植技术',
            itemTitle2: '营养液技术',
            itemTitle3: 'LED植物补光技术',
            itemDesc1: '自主研发，提供240余种蔬菜、特菜类、香料类、药用植物类、茄果类、嫩菜类、生菜类的全套种植方案，能够实现快速调取适合作物生长的光谱、营养液元素、温度、湿度相关参数，行业唯一，助力优产丰收。',
            itemDesc2: '独立研发通用型和特异型水培营养液，适用于不同种植场景和种植品种需要。目前已研发涵盖兰科，十字花科，景天科等十二大类植物的专项营养液配方，开展作物功能性研发试验，研发元素富集型营养液配方，例如（富钾富硒），将来可为特定人群的健康助力。',
            itemDesc3: '自主研发，提供各类植物品种的LED光配方，光配方包含因不同品种产生的光差异和同一品种不同生长阶段的光差异，光照配方植物的光谱配方包含光照强度（PPFD）光谱（光波段70nm-1000nm）人工光的主要作用就是替代太阳，实现全封闭生长。',
        },
        news: {
            title: '企业新闻',
            desc: '关注行业最新资讯，助力农业现代化发展'
        }
    },
    about: {
        carousel: {
            title: '企业介绍',
            title1: '首页',
            title2: '企业介绍'
        },
        aboutIntroduce: {
            title: '合肥创农生物科技有限公司',
            title1: '创农生物致力于成为全球领先的无土栽培全产业链服务商',
            p: '合肥创农生物科技有限公司（以下称创农生物）是一家集研发、生产、销售及技术服务于一体的高新技术企业，致力于向全球推广系统化、智能化、标准化的农业种植解决方案，各类种植设备定制及技术服务。',
            p1: ' 公司坐落于中铁北京局合肥创新科技园，自建研发生产基地，综合面积约17000㎡，下设有叶菜、瓜果、花卉、中药、菌菇、全智能等六大研培实验室，完成了240余种作物的无土栽培种植技术开发，拥有100余项发明专利软件著作等。于2021年控股山东亚凡信息科技有限公司、山东新程农业科技发展有限公司，2024年控股合工大化学与化工研究院下设公司完成行业上下游的产业布局。'

        },
        team: {
            title: '我们的团队',
            text: '设施农业解决方案专家',
            name: '解晓巍',
            designation: '创始人',
            name1: '王喜萍',
            designation1: '首席科学家',
            name2: '朱世波',
            designation2: '技术总监',
        },
        qualifications: {
            title: '企业资质',
            text: '关注行业最新资讯，助力农业现代化发展'
        },
        sub: {
            title: '叶菜侠科技',
            text: '专注叶菜生产技术研究，全球供应叶菜标准化生产解决方案'
        }
    },
    products: {
        carousel: {
            title: '产品中心',
            title1: '首页'
        },
        productsItem: {
            title: '产品中心',
            text: '为专业种植者提供卓越有效的作物生产系统'
        },
        author: '作者',
        time: '时间'
    },
    solution: {
        carousel: {
            title: '解决方案',
            title1: '首页'
        },
        productsItem: {
            title: '解决方案',
            text: '十四年深耕作物标准化生产工艺研究，将作物生产工艺与生产设施相结合形成成套化的生产装备系统',
        },
    },
    case: {
        carousel: {
            title: '项目案例',
            title1: '首页'
        },
        productsItem: {
            title: '项目案例',
            text: '用户需求用什么技术解决，比技术可以应用在什么领域更重要',
        },
    },
    news: {
        carousel: {
            title: '企业新闻',
            title1: '首页'
        },
        newsRight: {
            title: '植物工厂系统解决方案',
            title1: '推荐资讯'
        },
    },
    contact: {
        carousel: {
            title: '联系我们',
            title1: '首页',
        },
        mainInformation: {
            title: '公司信息',
            text: '合肥创农生物科技有限公司（以下称创农生物）是一家集研发、生产、销售及技术服务于一体的高新技术企业，致力于向全球推广系统化、智能化、标准化的农业种植解决方案，各类种植设备定制及技术服务。',
            address: '地址',
            detailedAddress: '合肥市肥西县经济开发区派河大道与苏岗路交口合肥创新科技园B3栋1-5层',
            phone: '电话',
            Email: '邮箱',
            messageBoard: '留言板',
            submit: '提交',
            map: '地图地址',
            name: '姓名',
            message: '留言',
            rules: {
                name: '请输入姓名',
                phone: '请输入电话',
                phone1: '请输入正确的手机号码',
                email: '请输入邮箱',
                content: '请输入留言内容',
            },
            submitSuccess: '提交成功',
            submitError: '提交失败，请稍后重试'
        }
    },
    footer: {
        copyright: 'Copyright @2024 合肥创农生物科技有限公司. All rights reserved.',
        record: '鲁ICP备2021041130号'
    },
    mobile: {
        company: '合肥创农生物科技有限公司',
        telephone: '电话',
        email: '邮箱',
        address: '地址',
        addressDetails: '山东省潍坊市寒亭区开元街道中央大街1号食品谷总部基地',
        copyright: '版权所有 皖ICP备2021015424号-1',
        home: '首页',
        products: '产品',
        news: '新闻'
    },
    switchLanguage: '切换语言',
    TopUp: '返回顶部',
    loadingText: '拼命加载中......'
}
