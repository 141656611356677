import axios from 'axios'

const service = axios.create({
    baseURL: '/api',
    timeout: 5000
})

service.interceptors.request.use(
    config => {
        const hostname = window.location.hostname;
        const language = localStorage.getItem('language') || (hostname.endsWith('.cn') ? 'en' : 'cn');
        if (config.params) {
            config.params.code = language;
        }

        if (config.data && typeof config.data === 'object') {
            config.data.code = language;
        }

        return config;
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        return Promise.reject(error)
    }
)

export default service
