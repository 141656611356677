<template>
  <div>
    <Header/>
    <main>
      <router-view :key="$route.fullPath"/>
    </main>
    <Footer/>
    <FloatNav/>
  </div>
</template>

<script>
import Header from '@/views/header/index.vue'
import Footer from "@/views/footer/footer.vue";
import FloatNav from "@/views/floatnav/index.vue";

export default {
  name: 'BasicLayout',
  components: {
    Header,
    Footer,
    FloatNav
  }
}
</script>