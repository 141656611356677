<template>
  <div class="float-nav">
    <div v-if="showInCurrentRoute" class="nav-item" @click="switchLang">
      <img alt="语言切换" src="@/assets/img/lang.png">
      <span class="tooltip">{{ $t('switchLanguage') }}</span>
    </div>
    <div v-if="showNav" class="nav-item" @click="scrollToTop">
      <img alt="返回顶部" src="@/assets/img/top.png">
      <span class="tooltip">{{ $t('TopUp') }}</span>
    </div>
  </div>
</template>

<script>
import { switchLanguage } from '@/i18n/index.js';
export default {
  name: 'FloatNav',
  data() {
    return {
      showNav: false,
      showInCurrentRoute: false,
      allowedRoutes: [
        '',
        '/about',
        '/products',
        '/solution',
        '/case',
        '/news',
        '/contact'
      ]
    }
  },
  watch: {
    '$route': {
      handler() {
        this.checkRoute();
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.showNav = scrollTop > 788
    },
    switchLang() {
      const currentLang = localStorage.getItem('language') || 'cn';
      const newLang = currentLang === 'cn' ? 'en' : 'cn';
      switchLanguage(newLang);
      window.location.reload();
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    checkRoute() {
      const currentPath = this.$route.path.replace(/\/$/, '')
      this.showInCurrentRoute = this.allowedRoutes.includes(currentPath)
    }
  },
}
</script>

<style scoped>
.float-nav {
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 999;
  transition: opacity 0.3s;
}

.nav-item {
  width: 40px;
  height: 40px;
  background: #666;
  border-radius: 4px;
  margin-bottom: 2px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.nav-item:hover {
  background: #61a93e;
}

.nav-item img {
  width: 20px;
  height: 20px;
}

.tooltip {
  position: absolute;
  right: 50px;
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  display: none;
}

.nav-item:hover .tooltip {
  display: block;
}
</style>
