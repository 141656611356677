import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import cn from './cn'

Vue.use(VueI18n)
const useDomainForLanguage = localStorage.getItem('useDomainForLanguage') !== 'false';

let manualSwitch = false;

// 根据域名设置初始语言
const getInitialLanguage = () => {
    const hostname = window.location.hostname;
    return hostname.endsWith('.cn') ? 'en' : 'cn';
};

const i18n = new VueI18n({
    locale: localStorage.getItem('language') || (useDomainForLanguage ? getInitialLanguage() : 'en'),
    messages: {
        en,
        cn
    }
});

console.log(`初始语言: ${i18n.locale}`);

window.onload = () => {
    const storedLanguage = localStorage.getItem('language');
    if (!storedLanguage) {
        const useDomainForLanguage = localStorage.getItem('useDomainForLanguage') !== 'false';
        if (useDomainForLanguage) {
            const initialLanguage = getInitialLanguage();
            i18n.locale = initialLanguage;
            localStorage.setItem('language', initialLanguage);
        }
    } else {
        i18n.locale = storedLanguage; 
    }
};

window.addEventListener('beforeunload', () => {
    if (!manualSwitch) {
        localStorage.removeItem('language');
        localStorage.removeItem('useDomainForLanguage');
    }
});

export const switchLanguage = (lang) => {
    i18n.locale = lang;
    localStorage.setItem('language', lang);
    localStorage.setItem('useDomainForLanguage', 'false');
    manualSwitch = true;
};

export default i18n
