const originalConsoleLog = console.log;
console.log = (...args) => {
  if (
    typeof args[0] === 'string' && (
      args[0].includes('Vue DevTools') ||
      args[0].includes('[Vue DevTools]') ||
      args[0].includes('Download the Vue Devtools') ||
      args[0].includes('v7 detected') ||
      args[0].includes('legacy version')
    )
  ) {
    return;
  }
  originalConsoleLog.apply(console, args);
};

import Vue from 'vue'
import i18n from './i18n'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/index.scss'

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
    i18n,
    router,
    render: h => h(App),
}).$mount('#app')
