import Vue from 'vue'
import i18n from '@/i18n'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  })
}

export const routes = [
    {
        path: '/',
        component: Layout,
        redirect: '/',
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('@/views/home/index'),
                meta: {title: '首页'}
            }
        ]
    },
    {
        path: '/about',
        component: Layout,
        children: [
            {
                path: '',
                name: 'about',
                component: () => import('@/views/about/index'),
                meta: {title: '企业介绍'}
            }
        ]
    },
    {
        path: '/products',
        component: Layout,
        children: [
            {
                path: '',
                name: 'products',
                component: () => import('@/views/products/index.vue'),
                meta: {title: '产品中心'}
            }
        ]
    },
    {
        path: '/singleCategoryList/detail/:id',
        component: Layout,
        children: [
            {
                path: '',
                name: 'singleCategoryList',
                component: () => import('@/views/products/singleCategoryList.vue'),
                meta: {title: '产品中心子分类详情'},
                props: true
            }
        ]
    },
    {
        path: '/solution',
        component: Layout,
        children: [
            {
                path: '/',
                name: 'solution',
                component: () => import('@/views/solution/index.vue'),
                meta: {title: '解决方案'}
            }
        ]
    },
    {
        path: '/categoryList/detail/:id',
        component: Layout,
        children: [
            {
                path: '',
                name: 'categoryList',
                component: () => import('@/views/solution/categoryList.vue'),
                meta: {title: '解决方案子分类详情'},
                props: true
            }
        ]
    },
    {
        path: '/case',
        component: Layout,
        children: [
            {
                path: '',
                name: 'case',
                component: () => import('@/views/case/index.vue'),
                meta: {title: '项目案例'}
            }
        ]
    },
    {
        path: '/caseDetail/detail/:id',
        component: Layout,
        children: [
            {
                path: '',
                name: 'caseDetail',
                component: () => import('@/views/case/caseDetail.vue'),
                meta: {title: '案例详情'},
                props: true
            }
        ]
    },
    {
        path: '/news',
        component: Layout,
        children: [
            {
                path: '',
                name: 'news',
                component: () => import('@/views/news/index.vue'),
                meta: {title: '企业新闻'}
            }
        ]
    },
    {
        path: '/newsDetail/detail/:id',
        component: Layout,
        children: [
            {
                path: '',
                name: 'newsDetail',
                component: () => import('@/views/news/newsDetail.vue'),
                meta: {title: '企业新闻详情'},
                props: true
            }
        ]
    },
    {
        path: '/contact',
        component: Layout,
        children: [
            {
                path: '',
                name: 'contact',
                component: () => import('@/views/contact/index.vue'),
                meta: {title: '联系我们'}
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: '/',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
})

router.beforeEach((to, from, next) => {
    // 根据当前语言设置标题
    const lang = i18n.locale;
    document.title = lang === 'cn'
      ? '植物工厂 一 合肥创农生物科技有限公司'
      : 'Plant factory one by one 一 Hefei Crownow Biotechnology Co., Ltd';
    next();
  });

export default router
