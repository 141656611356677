import request from '@/utils/request'

// 首页 轮播图
export function getBannersList(data) {
    return request({
        url: '/website/banner/list',
        method: 'get',
        params: {
            ...data
        },
    })
}

// 首页 产品展示
export function getProductsList(data) {
    return request({
        url: '/website/product/home/show',
        method: 'get',
        params: {
            ...data
        },
    })
}

// 顶部导航 产品中心二级菜单
export function getProductDropDownMenu(data) {
    return request({
        url: '/website/product/list',
        method: 'get',
        params: {
            ...data
        },
    })
}

// 顶部导航 解决方案二级菜单
export function getSolutionDropDownMenu(data) {
    return request({
        url: '/website/solution/list',
        method: 'get',
        params: {
            ...data
        },
    })
}

// 首页 解决方案
export function getSolutionList(data) {
    return request({
        url: '/website/solution/home/show',
        method: 'get',
        params: {
            ...data
        },
    })
}

// 首页 案例列表
export function getCaseList(data) {
    return request({
        url: '/website/case/list',
        method: 'get',
        params: {
            ...data
        },
    })
}

// 首页 新闻列表
export function getNewsList(data) {
    return request({
        url: '/website/news/list',
        method: 'get',
        params: {
            ...data
        },
    })
}

// 首页 底部资讯
export function getLatestNews(data) {
    return request({
        url: '/website/getLatestNews',
        method: 'get',
        params: {
            ...data
        },
    })
}


// 产品中心 全部产品
export function getProductsSubcategoryList(data) {
    return request({
        url: '/website/product/show',
        method: 'get',
        params: {
            ...data
        },
    })
}

// 产品中心 产品详情
export function getProductInfo(data) {
    return request({
        url: '/website/product/info/' + data,
        method: 'get',
    })
}

// 解决方案 全部方案
export function getCategoryList(data) {
    return request({
        url: '/website/solution/show',
        method: 'get',
        params: {
            ...data
        },
    })
}


// 解决方案 方案详情
export function solutionDetail(data) {
    return request({
        url: '/website/solution/info/' + data,
        method: 'get',
    })
}

// 案例列表
export function caseList(data) {
    return request({
        url: '/website/case/page/list',
        method: 'post',
        data: {
            pageNum: data.pageNum,
            pageSize: data.pageSize
        }
    })
}

// 案例详情
export function caseDetail(data) {
    return request({
        url: "/website/getCaseInfo/" + data,
        method: 'get',
    })
}

// 新闻列表
export function newsList(data) {
    return request({
        url: '/website/news/page/list',
        method: 'post',
        data: {
            pageNum: data.pageNum,
            pageSize: data.pageSize
        }
    })
}

// 新闻详情
export function newsDetail(data) {
    return request({
        url: "/website/getNewsInfo/" + data,
        method: 'get',
    })
}

// 推荐资讯
export function newsRecommend(data) {
    return request({
        url: "/website/getLatestNews",
        method: 'get',
        params: { 
            ...data
        }
    })
}

// 表单提交
export function formSubmission(data) {
    return request({
        url: "/website/addNewRecord",
        method: 'POST',
        data: {
            ...data
        }
    })
}