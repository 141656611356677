<template>
  <div>
    <div class="main-footer">
      <div class="footer-top">
        <div class="footer-item3">
          <h2>{{ $t('contact.mainInformation.messageBoard') }}</h2>
          <el-form ref="form" :model="form" :rules="formRules">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item prop="name">
                  <el-input v-model="form.name" :placeholder="$t('contact.mainInformation.name')"></el-input>
                </el-form-item>
              </el-col>
              <el-col v-if="!isEnglish" :span="12">
                <el-form-item prop="phone">
                  <el-input v-model="form.phone" :placeholder="$t('contact.mainInformation.phone')"></el-input>
                </el-form-item>
              </el-col>
              <el-col v-if="isEnglish" :span="12">
                <el-form-item prop="email">
                  <el-input v-model="form.email" :placeholder="$t('contact.mainInformation.Email')"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item prop="content">
              <el-input v-model="form.content" :placeholder="$t('contact.mainInformation.message')" rows="4"
                        type="textarea"/>
            </el-form-item>
            <div class="button-r">
              <el-button type="success" @click="onSubmit('form')">{{ $t('contact.mainInformation.submit') }}</el-button>
            </div>
          </el-form>
        </div>
        <div class="footer-item1">
          <router-link to="/contact"><h2>{{ $t('nav.contact') }}</h2></router-link>
          <ul class="contact-info">
            <li><img alt="" src="@/assets/img/position.png">{{ $t('nav.address') }}</li>
            <li><img alt="" src="@/assets/img/phone.png">+86-19314091645</li>
            <li><img alt="" src="@/assets/img/message.png"> global@senshcn.com</li>
          </ul>
        </div>
        <div class="footer-item2">
          <router-link to="/about"><h2>{{ $t('nav.about') }}</h2></router-link>
          <p>{{ $t('nav.describe') }}</p>
        </div>
        <div class="footer-item4">
          <router-link to="/case"><h2>{{ $t('nav.latestNews') }}</h2></router-link>
          <div v-for="item in latestNewsList" :key="item.id" class="news-content">
            <img :src="item.img" alt="img">
            <div class="newsDetails" @click="goNewsDetails(item.id)">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div>{{ $t('footer.copyright') }}
            <template v-if="!isEnglish">
                <a href="https://beian.miit.gov.cn" target="_blank"> &nbsp; 皖ICP备2021015424号-1</a>
            </template>
        </div>
      </div>
    </div>
    <div class="footer-mo">
      {{ $t('mobile.company') }}<br>
      {{ $t('mobile.email') }}：global@senshcn.com<br>
      {{ $t('mobile.telephone') }}：+86-19314091645<br>
        <template v-if="!isEnglish">
            版权所有 皖ICP备2021015424号-1
        </template>
    </div>
    <div class="m-b-bar">
      <div class="m-navigation" @click="goToPage('home')">
        <img alt="" src="@/assets/img/home.png">
        {{ $t('mobile.home') }}
      </div>
      <div class="m-navigation" @click.stop="goToPage('products')">
        <img alt="" src="@/assets/img/product.png">
        {{ $t('mobile.products') }}
      </div>
      <div class="m-navigation" @click="goToPage('news')">
        <img alt="" src="@/assets/img/news.png">
        {{ $t('mobile.news') }}
      </div>
      <div class="m-navigation">
        <a href="tel:19314091645">
          <img alt="" src="@/assets/img/phone2.png">
          {{ $t('mobile.telephone') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import {formSubmission, getLatestNews, getProductDropDownMenu} from "@/api";

export default {
  name: 'footerIndex',
  inject: ['reload'],
  data() {
    return {
      productSubmenu: [],
      latestNewsList: [],
      form: {
        name: '',
        phone: '',
        email: '',
        content: ''
      }
    }
  },
  created() {
    this.getList()
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en';
    },
    formRules() {
      return {
          name: [
            {required: true, message: this.$t('contact.mainInformation.rules.name'), trigger: 'blur'}
          ],
          phone: [
            {required: !this.isEnglish, message: this.$t('contact.mainInformation.rules.phone'), trigger: 'blur'},
            {pattern: /^1[3-9]\d{9}$/, message: this.$t('contact.mainInformation.rules.phone1'), trigger: 'blur'}
          ],
          email: [
            {required: this.isEnglish, message: this.$t('contact.mainInformation.rules.email'), trigger: 'blur'},
            {
              type: 'email',
              message: this.$t('contact.mainInformation.rules.email'),
              trigger: 'blur'
            }
          ],
          content: [
            {required: true, message: this.$t('contact.mainInformation.rules.content'), trigger: 'blur'}
          ]
      }
    }
  },
  methods: {
    getList() {
      getProductDropDownMenu().then((res => {
        this.productSubmenu = res.data
      }))
      getLatestNews().then((res => {
        res.data.forEach(row => {
          if (row.img) {
            row.img = `${process.env.BASE_API}${row.img}`;
          }
        });
        this.latestNewsList = res.data
      }))
    },
    goNewsDetails(id) {
      if (this.$route.params.id === id) {
        window.scrollTo(0, 0);
      } else {
        this.$router.push({
          name: 'newsDetail',
          params: {id}
        }).then(() => {
          this.reload();
        });
      }
    },
    goToPage(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({name: routeName})
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            const params = {
              name: this.form.name,
              content: this.form.content
            };

            if (this.isEnglish) {
              params.email = this.form.email;
            } else {
              params.phone = this.form.phone;
            }
            await formSubmission(params);
            this.$refs[formName].resetFields();
            this.$message.success(this.$t('contact.mainInformation.submitSuccess'));
          } catch (error) {
            console.error('提交表单出错:', error);
            this.$message.error(this.$t('contact.mainInformation.submitError'));
          }
        }
      });
    }
  }
}
</script>
