export default {
    nav: {
        title: 'Plant factory one by one一一Hefei Crownow Biotechnology Co., Ltd',
        home: 'Home',
        about: 'About Us',
        products: 'Products',
        solution: 'Solutions',
        case: 'Cases',
        news: 'News',
        contact: 'Contact Us',
        latestNews: 'Latest News',
        describe: 'Hefei Crownow Biotechnology Co., Ltd. (hereinafter referred to as Crownow Biotechnology) is a high-tech enterprise that integrates research and development, production, sales, and technical services. It is committed to promoting systematic, intelligent, and standardized agricultural planting solutions, various planting equipment customization, and technical services to the world',
        address: 'Floors 1-5, Building B3, Hefei Innovation and Technology Park, Intersection of Paihe Avenue and Sugang Road, Feixi County Economic Development Zone, Hefei City'
    },
    home: {
        featured: {
            title: 'Hydroponic Vertical Farms: a new beginning! Are you ready? We are.',
            title1: 'Planting Design',
            title2: 'Technologies standard',
            title3: 'Operation',
            desc: 'From soil cultivation to soilless cultivation to automated plant factories, Yecaixia Technology continues to focus on the research of leafy vegetable production technology',
            desc1: 'Comprehensive design for indoor vertical hydroponic farms, including greenhouse layouts, nutrient systems, seedling programs, water supply, electrical circuits, and data monitoring for pH, EC, and CO₂.',
            desc2: 'Indoor vertical hydroponic farming with automated nutrient standards, EC/pH/CO₂ sensors, temperature and humidity control, and AB nutrient solution configuration.',
            desc3: 'Hydroponic Cultivation Instructions for Over 250 Plant Species, Indoor Farm Environmental Control, LED Spectrum Adjustment, and Automated Nutrient Solution Management.'
        },
        solution: {
            title: 'We provide standardized production solutions for leafy vegetables based on three scenarios',
            desc: 'From soil cultivation to soilless cultivation to automated plant factories, Leafyman Technology continues to focus on leafy vegetable production technology research'
        },
        products: {
            title: 'Crownow: Redefining Indoor Agriculture with Vertical Hydroponics',
            desc: 'Grow Fresh, Anywhere, Anytime—No Soil, No Climate Limits'
        },
        system: {
            title: 'The all-new "array" system',
            title2: 'Automated DFT hydroponic solution',
            desc: '“LEAFYMAN ®️ array ®️” The automated DFT hydroponic system is a solution designed specifically for modern cities to achieve differentiated annual production of leafy vegetable products near the city. The system consists of a greenhouse, DFT hydroponic system, and automated production line. To cope with the increasingly tight agricultural labor force and urban green supply chain, automated hydroponic systems can achieve uninterrupted supply of leafy vegetables for 365 days with few people/unmanned operation.'
        },
        Solutions: {
            title: 'Complex Scenarios, Simplified Hydroponic Solutions',
            desc: 'Build mobile, modular farms that work anywhere, no matter the climate'
        },
        case: {
            title: 'Project Case',
            desc: 'What technology can be used to solve user needs is more important than what field technology can be applied in'
        },
        introduce: {
            title: 'Make indoor planting simple and efficient',
            title1: 'It is the purpose of Crownow Biotechnology',
            title2: 'How to ensure simple and efficient indoor planting in all aspects',
            itemTitle1: 'Planting',
            itemTitle2: 'Nutrient solution',
            itemTitle3: 'Supplement Light',
            itemDesc1: 'Independently developed, providing a complete set of planting plans for more than 240 types of vegetables, special dishes, spices, medicinal plants, eggplants, tender vegetables, and lettuce. It can quickly obtain spectral, nutrient solution elements, temperature, and humidity related parameters suitable for crop growth, which is unique in the industry and helps achieve excellent production and harvest.',
            itemDesc2: 'Independently develop universal and specific hydroponic nutrient solutions, suitable for different planting scenarios and varieties. At present, we have developed specialized nutrient solution formulas covering twelve major categories of plants, including orchids, cruciferous plants, and Sedum plants. We have conducted functional research and development experiments on crops and developed nutrient solution formulas that are enriched in elements, such as potassium and selenium, which can provide health assistance for specific populations in the future.',
            itemDesc3: 'Independently developed, we provide LED light formulas for various plant varieties. The light formulas include the light differences caused by different varieties and the light differences at different growth stages of the same variety. The spectral formula for plants includes light intensity (PPFD) spectrum (light band 70nm-1000nm). The main function of artificial light is to replace the sun and achieve fully enclosed growth.',
        },
        news: {
            title: 'Enterprise News',
            desc: 'Pay attention to the latest industry news and assist in the modernization of agriculture development'
        }
    },
    about: {
        carousel: {
            title: 'About Us',
            title1: 'Home',
            title2: 'Crownow',
        },
        aboutIntroduce: {
            title: 'Hefei Crownow Biotechnology Co., Ltd',
            title1: 'Crownow Biotechnology is committed to becoming a leading global provider of soilless cultivation full industry chain services',
            p: 'Hefei Crownow Biotechnology Co., Ltd. (hereinafter referred to as Crownow Biotechnology) is a high-tech enterprise that integrates research and development, production, sales, and technical services. It is committed to promoting systematic, intelligent, and standardized agricultural planting solutions, various planting equipment customization, and technical services to the world.',
            p1: 'The company is located in the Hefei Innovation Science and Technology Park of China Railway Beijing Bureau, with a self built research and development production base and a comprehensive area of about 17000 square meters. It has six major research and training laboratories, including leafy vegetables, fruits, flowers, traditional Chinese medicine, mushrooms, and fully intelligent technology. It has completed the development of soilless cultivation techniques for more than 240 crops and has more than 100 invention patents and software works. In 2021, it acquired controlling stakes in Shandong Yafan Information Technology Co., Ltd. and Shandong Xincheng Agricultural Technology Development Co., Ltd. In 2024, it acquired controlling stakes in a subsidiary of the Chemical and Chemical Research Institute of Hefei University of Technology to complete the industrial layout of the upstream and downstream industries.'

        },
        team: {
            title: 'Our team',
            text: 'Expert in Facility Agriculture Solutions',
            name: 'Xie Xiaowei',
            designation: 'Founder',
            name1: 'Wang Xiping',
            designation1: 'Chief scientist',
            name2: 'Zhu Shibo',
            designation2: 'Technical Director',
        },
        qualifications: {
            title: 'Enterprise qualification',
            text: 'Pay attention to the latest industry news and assist in the modernization of agriculture development'
        },
        sub: {
            title: 'Yecai Xia Technology',
            text: 'Focusing on the research of leafy vegetable production technology, providing standardized production solutions for leafy vegetables worldwide'
        }
    },
    products: {
        carousel: {
            title: 'Products',
            title1: 'Home',
        },
        productsItem: {
            title: 'Products',
            text: 'Provide excellent and effective crop production systems for professional growers',
        },
        author: 'Author',
        time: 'Time'
    },
    solution: {
        carousel: {
            title: 'Solution',
            title1: 'Home',
        },
        productsItem: {
            title: 'Solution',
            text: 'Fourteen years of in-depth research on standardized production processes for crops, combining crop production processes with production facilities to form a complete set of production equipment systems',
        },
    },
    case: {
        carousel: {
            title: 'Cases',
            title1: 'Home',
        },
        productsItem: {
            title: 'Cases',
            text: 'What technology can be used to solve user needs is more important than what field technology can be applied in',
        },
    },
    news: {
        carousel: {
            title: 'News',
            title1: 'Home',
        },
        newsRight: {
            title: 'Plant Factory System Solution',
            title1: 'Suggested Resources',
        },
    },
    contact: {
        carousel: {
            title: 'Contact Us',
            title1: 'Home',
        },
        mainInformation: {
            title: 'Company Information',
            text: 'Hefei Crownow Biotechnology Co., Ltd. (hereinafter referred to as Crownow Biotechnology) is a high-tech enterprise that integrates research and development, production, sales, and technical services. It is committed to promoting systematic, intelligent, and standardized agricultural planting solutions, various planting equipment customization, and technical services to the world.',
            address: 'Address',
            detailedAddress: 'Floors 1-5, Building B3, Hefei Innovation and Technology Park, Intersection of Paihe Avenue and Sugang Road, Feixi County Economic Development Zone, Hefei City',
            phone: 'Phone',
            Email: 'Email',
            messageBoard: 'Message board',
            submit: 'Submit',
            map: 'Map address',
            name: 'Name',
            message: 'Message',
            rules: {
                name: 'Please enter your name',
                email: 'Email format error',
                content: 'Please enter the message content',
            },
            submitSuccess: 'Submitted successfully',
            submitError: 'Submission failed, please try again later'
        }
    },
    footer: {
        copyright: 'Copyright @2024. Hefei Crownow Biotechnology Co., Ltd. All rights reserved.',
        record: 'Lu ICP No. 2021041130'
    },
    mobile: {
        company: 'Hefei Crownow Biotechnology Co., Ltd',
        telephone: 'Telephone',
        email: 'Email',
        address: 'Address',
        addressDetails: 'Food Valley Headquarters Base, No.1 Central Street, Kaiyuan Street, Hanting District, Weifang City, Shandong Province',
        home: 'Home',
        products: 'Products',
        news: 'News'
    },
    switchLanguage: 'switch Language',
    TopUp: 'Top',
    loadingText: 'Desperately loading......'
}
